import React from 'react';

export default function Home() {
    return(
        <section className="hero is-dark is-fullheight">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title has-text-centered">
                        Per poter accedere all'area riservata richiedi al tuo agente il token d'accesso
                    </h1>
                </div>
            </div>
        </section>
    )
}