import React from 'react';
import api from '../services/api'
import { FireworkSpinner } from "react-spinners-kit";
import {
    Link
  } from "react-router-dom";
  import Header from "../components/Header";
  import ReactMarkdown from 'react-markdown';

class Collections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            collections:[]
        };
    }


    async componentDidMount(){
        const { token } = this.props.match.params;
        const resp = await api.get(`/collections/front?access_token=${token}`).catch(() => {})
        if(resp){
            setTimeout(() => {
                this.setState({loading:false, collections:resp.data}) 
            }, 500);
        }
    }

    render(){
        const { loading, collections } = this.state;
        const { token } = this.props.match.params;
        if(loading){
            return (
                <div style={{display:'flex', backgroundColor:'black', justifyContent:'center', alignItems:'center', alignContent:'center', height:'100vh'}}>
                    <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                        <FireworkSpinner
                            size={30}
                            color="white"
                            loading={loading}
                        />
                        <h1 style={{color:'white'}}>caricamento...</h1>
                    </div>
                </div>
            );
        }
        
        return(
            <div className="container">
                <Header token={token}/>
                <div className="collections-body">
                    {collections.map(collection => {
                        return(
                            <div key={collection.id} className="columns collection-container" style={{flexDirection:'column', borderBottom:'1px solid'}} >
                                <Link to={`/${token}/collections/${collection.id}`} style={{textDecoration:'none'}}>
                                    <div className="column has-text-centered">
                                        <img style={{maxWidth:800, width: '100%'}} src={`${process.env.REACT_APP_API}/${collection.image}`} alt="" />
                                    </div>
                                    <div className="column has-text-centered">
                                        <h4 style={{color:'black', fontWeight:600}} className="is-size-5">{collection.nome_collezione}</h4>
                                    </div>
                                </Link>
                                <div className="column has-text-centered">
                                    <ReactMarkdown style={{color:'black', marginBottom:20}} source={collection.description} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default Collections