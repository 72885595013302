import React, {useEffect} from 'react';
import SelectSeason from './views/SelectSeason';
import Collections from './views/Collections';
import Home from './views/Home';
import SingleCollection from './views/SingleCollection';
import Item from './views/Item';
import WishList from './views/WishList';
import Unauthorized from './views/Unauthorized';
import NoMatch from './views/NoMatch';
import './css/bulma.min.css';
import './css/style.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import $ from 'jquery';

function App() {

  useEffect(() => {
    $(document).on('contextmenu', 'img', function() {
      return false;
    });
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/" component={Home} />
        {/* <Route exact path="/select-season" component={SelectSeason} /> */}
        <Route exact path="/:token" component={Collections} /> 
        <Route exact path="/:token/collections/:id" component={SingleCollection} />
        <Route exact path="/:token/collections/:id/product/:item_id" component={Item} />
        <Route exact path="/:token/wish-list" component={WishList} />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  )
}

export default App;



