import React from 'react';
import $ from 'jquery';
import api from '../services/api';
import ModalCart from '../components/ModalCart';
import { FireworkSpinner } from "react-spinners-kit";
import {
    Link
  } from "react-router-dom";
import Config from "../config";
import Header from "../components/Header";
import Plus from "../images/plus.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            item:null,
            selectedSize:{},
            sizeSaved:false,
            isModalActive:false
        };
        this.modalCartRef = React.createRef();
    }

    async componentDidMount(){
        const { token, id, item_id } = this.props.match.params;
        const resp = await api.get(`/items/${item_id}/collection/${id}/self-relations?access_token=${token}`).catch(() => {})
        if(resp){
            let selected = {}  
            let saved = false          
            if(resp.data.wish_list_items.length > 0){
                resp.data.wish_list_items.forEach(wish => {
                    selected[wish.size] = wish.qty 
                });
                saved = true
            }
            
            const sizes = this.stringSizeToArray(resp.data.size)
            sizes.forEach(size => {
                if(!(size in selected)){
                    selected[size] = 0
                }
            });

            setTimeout(() => {
                this.setState({loading:false, item:resp.data, selectedSize:selected, sizeSaved:saved})
            }, 500);
            
        }
    }

    componentDidUpdate(){
        $(document).ready(function(){
            const height = $(window).height();
            const width = $(window).width();
              if(width > 767){
                  $('#vertical-scroll-images').css('height', height - 194);
                  $('.fixed-heigth-container').css('height', height - 205);
                  $('#menu').css('margin-top', '12px')
              }else{
                //   $('body').addClass('block-scroll')
              }
        })
    }

    componentWillUnmount() {
        // $('body').removeClass('block-scroll')
    }

    stringSizeToArray = size => {
        let sizeArr = []
        let _size = size.split('-')
        let start = parseInt(_size[0])
        let end = parseInt(_size[1])
        let count = end - start
        for (let i = 0; i <= count; i++) {
            sizeArr.push(start+i)
        }

        return sizeArr        
    }

    updateSizeQty = (selectedSize) => {
        this.setState({selectedSize:selectedSize, isModalActive:false}, () => {
            this.addToSelection()
        })
    }
    
    updateInputValue = (evt, size) => {
        if(isNaN(evt.target.value)){
            return 
        }

        const value = parseInt(evt.target.value)

        let selected = this.state.selectedSize
        selected[size] = value
        this.setState({selectedSize:selected})
    }

    addToSelection = async () => {
        this.setState({loading:true})
        let sizes = []
        for (const key in this.state.selectedSize) {
            if (this.state.selectedSize.hasOwnProperty(key)) {
                const size = this.state.selectedSize[key];
                sizes.push({[key]:size})
            }
        }

        const { token, id, item_id } = this.props.match.params;
        const obj = {
            item:item_id,
            sizes:sizes
        }
        const resp = await api.post(`/wish-list-items/update-or-create-wishlist?access_token=${token}`, obj).catch(() => {})
        if(resp){
            this.setState({loading:false, sizeSaved:true})
        }
    }

    openModal = () => {
        this.setState({isModalActive:!this.state.isModalActive}, () => {
            if(this.modalCartRef.current) {
                this.modalCartRef.current.setBaseData(this.state.selectedSize)
            }
        })
    }

    modalCallback = () => {
        this.setState({isModalActive:false})
    }

    render(){
        const { loading, item, sizeSaved, isModalActive } = this.state;
        const { token, id, item_id } = this.props.match.params;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        if(loading){
            return (
                <div style={{display:'flex', backgroundColor:'black', justifyContent:'center', alignItems:'center', alignContent:'center', height:'100vh'}}>
                    <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                        <FireworkSpinner
                            size={30}
                            color="white"
                            loading={loading}
                        />
                        <h1 style={{color:'white'}}>caricamento...</h1>
                    </div>
                </div>
            );
        }
        
        return(
            <React.Fragment>
                {isModalActive && (
                    <ModalCart ref={this.modalCartRef} item={item} updateSizeQty={this.updateSizeQty} modalCallback={this.modalCallback} />
                )}

                <div className="container">

                    <Header token={token} collectionName={item.collection_id.nome_collezione} />
                    
                    <div id="item-body" className="columns" >
                        <div className="column item-left-container">
                            <div id="vertical-scroll-images">
                                <div className="columns">
                                    {item.images.map((image, index) => 
                                        <div key={index} className="column">
                                            <img src={`${process.env.REACT_APP_API}/${image}`} alt="" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div id="images-carousel" className="column">
                            <Slider {...settings}>
                                {item.images.map((image, index) => 
                                    <div key={index}>
                                        <img src={`${process.env.REACT_APP_API}/${image}`} alt="" />
                                    </div>
                                )}
                            </Slider>
                        </div>
                        <div className="column item-description-container">
                            <div className="fixed-heigth-container" style={{background: 'rgb(234, 234, 226)'}}>
                                <div className="columns" style={{flexDirection:'column'}}>
                                    <div className="column bb-1" style={{background: 'rgb(210,211,203)'}}>
                                        <div style={{padding:'30px 20px'}}>
                                            <h1 className="is-size-3 has-text-weight-bold lh-2rem">{item.collection_id.nome_collezione} {item.lining == 'SHEARLING' ? item.lining : item.material}</h1>
                                            {/* <h1 className="is-size-3 has-text-weight-bold lh-2rem">{item.description}</h1> */}
                                            <h3 className="is-size-6 lh-2rem">{item.sku}</h3>
                                        </div>
                                    </div>
                                    <div id="item-price-container" className="column bb-1" style={{background: 'rgb(234, 234, 226)'}}>
                                        <div className="columns">
                                            <div className="column" style={{borderRight:'1px solid'}}><h3 className="has-text-centered is-size-6">W. SALE PRICE <b>{item.valuta} {item.whs_price}</b></h3></div>
                                            <div className="column"><h3 className="has-text-centered is-size-6">RETAIL PRICE <b>{item.valuta} {item.ret_price}</b></h3></div>
                                        </div>
                                    </div>
                                    <div className="column bb-1" style={{background: 'rgb(234, 234, 226)'}}>
                                        <div className="columns">
                                            <div className="column" style={{padding:30}}>
                                                <h3 className="is-size-6">ITEM DETAILS</h3>
                                                <div  style={{marginTop:20}}>
                                                    <ul>
                                                        <li className="is-size-7">{item.description}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="item-size-list" className="column" style={{borderBottom:'1px solid', background: 'rgb(97,107,111)', color:'white'}}>
                                        <div className="columns">
                                            <div className="column">
                                                <h5 className="has-text-centered is-size-7">Size</h5>
                                            </div>

                                            {this.stringSizeToArray(item.size).map((size, index) => {
                                                return(
                                                    <div key={index} className="column">
                                                        <h5 className="has-text-centered is-size-7">{size}</h5>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div id="item-qty-container" className="column" style={{borderBottom:'1px solid'}}>
                                        <div className="columns">
                                            <div className="column" style={{background: 'rgb(234, 234, 226)'}}>
                                                <h5 className="has-text-centered is-size-7">Qty</h5>
                                            </div>
                                            {this.stringSizeToArray(item.size).map((size, index) => {
                                                return(
                                                    <div key={index} className="column" style={{background: 'white'}}>
                                                        <input type="text" value={this.state.selectedSize[size] || ''} onChange={e => this.updateInputValue(e, size)} placeholder="0" style={{border:'none', width:'100%', textAlign:'center'}}/>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div id="item-description" className="column" style={{paddingBottom:80, background: 'rgb(234, 234, 226)'}}>
                                        <div className="columns">
                                            <div className="column" style={{paddind:30}}>
                                                <p className="is-size-7" style={{padding:20}}>
                                                    {item.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="add-wish-button-container">
                                        <div className="column">
                                            <div className="columns">
                                                <div style={{cursor:'pointer'}} className="column">
                                                    <h3 onClick={this.openModal} className="has-text-centered">INSERT QTY / SIZE</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="add-update-selection" className="column" style={{padding: 0,paddingLeft: 12}}>
                                            <div className="columns">
                                                <div id="add-to-selection" className="column is-9 is-three-quarters-mobile">
                                                    <h3 onClick={this.addToSelection} className="has-text-centered">
                                                        {sizeSaved ? (<span>UPDATE SELECTION</span>): (<span>ADD TO SELECTION</span>)}
                                                    </h3>
                                                </div>
                                                <div id="plus-button-container" className="column has-text-centered">
                                                    <img style={{height:30}} src={Plus} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Item