import React from 'react'

export default function Unauthorized(){
    return (
        <section className="hero is-dark is-fullheight">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title has-text-centered">
                        Token expired
                    </h1>
                    <h2 className="subtitle has-text-centered">
                        Please ask your Agent for a new invitation.
                    </h2>
                </div>
            </div>
        </section>
    )
}