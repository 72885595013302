import React from 'react';
import $ from 'jquery';
import api from '../services/api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FireworkSpinner } from "react-spinners-kit";
import {
    Link
  } from "react-router-dom";
import Config from "../config";
import Header from "../components/Header";

class WishList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            wish_list_items:[],
            updateItems:[]
        };
    }

    async getData(){
        const { token } = this.props.match.params;
        const resp = await api.get(`/wish-lists/from-token?access_token=${token}`).catch(() => {
            this.setState({loading:false, wish_list_items:[]})
        })
        if(resp){
            let wish = []
            resp.data.wish_list_items.forEach(_wish => {
                const found = wish.findIndex(w => w.id == _wish.item.id)
                if(found === -1){
                    let item = _wish.item
                    item.thumb =_wish.thumb
                    item.sizes = [{id:_wish.id, size:_wish.size, qty:_wish.qty}]
                    wish.push(item)
                }else{
                    wish[found].sizes.push({id:_wish.id, size:_wish.size, qty:_wish.qty})
                }
            });

            setTimeout(() => {
                this.setState({loading:false, wish_list_items:wish})
            }, 500);
        }
    }

    async componentDidMount(){
        this.getData()    
    }

    returnSizeValue = (item_id, i) => {
        const found = this.state.wish_list_items.find(item => item.id === item_id)
        const size = found.sizes.find(s => s.size == i)
        if(size){
            return size.qty+''
        }
        
        return ''
    }

    updateInputValue = (evt, item_id, size) => {

        let _v = parseInt(evt.target.value)

        if(isNaN(parseInt(evt.target.value))){
            _v = 0
        }
        
        const value = _v
        

        let updateItems = JSON.parse(JSON.stringify(this.state.updateItems)) 
        const wish_list_items = JSON.parse(JSON.stringify(this.state.wish_list_items)) 
        const foundIndex = wish_list_items.findIndex(item => item.id === item_id)
        const sizeIndex = wish_list_items[foundIndex].sizes.findIndex(s => s.size == size)
        if(sizeIndex !== -1){
            wish_list_items[foundIndex].sizes[sizeIndex].qty = value
        }else{
            wish_list_items[foundIndex].sizes.push({
                qty:value,
                size:size
            })
        }

        const updateItemsIndex = updateItems.findIndex(i => i.item_id == item_id && i.size == size)
        if(updateItemsIndex !== -1){
            updateItems[updateItemsIndex].qty = value
        }else{
            let _item = {
                item_id:item_id,
                qty:value,
                size:size
            }

            if(sizeIndex !== -1){
                _item.id = wish_list_items[foundIndex].sizes[sizeIndex].id
            }

            updateItems.push(_item)
        }

        this.setState({wish_list_items:wish_list_items, updateItems:updateItems})
    }

    updateServerValues = async (e) => {
        const { token } = this.props.match.params;
        const resp = await api.post(`/wish-list-items/update-or-create-wishlist-items?access_token=${token}`, {items:this.state.updateItems}).catch(() => {})
        if(resp){
            let wish = []

            resp.data.wish_list_items.forEach(_wish => {
                const found = wish.findIndex(w => w.id == _wish.item.id)
                if(found === -1){
                    let item = _wish.item
                    item.thumb =_wish.thumb
                    item.sizes = [{id:_wish.id, size:_wish.size, qty:_wish.qty}]
                    wish.push(item)
                }else{
                    wish[found].sizes.push({id:_wish.id, size:_wish.size, qty:_wish.qty})
                }
            });

            this.setState({loading:false, wish_list_items:wish, updateItems:[]})
        }
    }

    sendEmail = async () => {
        this.setState({loading:true})
        const { token } = this.props.match.params;
        const resp = await api.post(`/wish-lists/send-review-email?access_token=${token}`).catch(() => {})
        if(resp){
            this.setState({loading:false})
            toast.success(resp.data.message);
        }
    }

    checkSizeAvailability = (item_id, size) => {
        const found = this.state.wish_list_items.find(item => item.id === item_id)
        if(!found){
            return false
        }
        const sizes = found.size.split('-') 
        if (size >= parseInt(sizes[0]) && size <= parseInt(sizes[1])) {
            return true
          }

          return false

    }

    returnTotalQty = (item_id) => {
        const found = this.state.wish_list_items.find(item => item.id === item_id)
        let total = 0
        found.sizes.forEach(size => {
            if(isNaN(parseInt(size.qty))){
                total += 0
            }else{
                total += parseInt(size.qty)
            }            
        });
        return total
    }
    
    returnTotalPrice = (item_id) => {
        const found = this.state.wish_list_items.find(item => item.id === item_id)
        let total = 0
        found.sizes.forEach(size => {
            if(isNaN(parseInt(size.qty))){
                total += 0
            }else{
                total += parseInt(size.qty)
            }            
        });
        return total * parseFloat(found.whs_price)
    }

    removeItem = async (e, item) => {
        e.preventDefault()
        this.setState({loading:true})
        const { token } = this.props.match.params;
        const resp = await api.delete(`/wish-list-items/${item.id}/remove-item?access_token=${token}`).catch(() => {})
        if(resp){
            toast.success(resp.data.message);
            await this.getData()
            this.setState({loading:false})
        }
    }

    calculateTotalWish = () => {
        let items = JSON.parse(JSON.stringify(this.state.wish_list_items))
        let total = 0

        items.forEach(item => {
            item.sizes.forEach(size => {
                total += item.whs_price*parseInt(size.qty)
            });
        });

        return total
    }
    
    calculateTotalQuantityWish = () => {
        let items = JSON.parse(JSON.stringify(this.state.wish_list_items))
        let total = 0

        items.forEach(item => {
            item.sizes.forEach(size => {
                total += parseInt(size.qty)
            });
        });

        return total
    }

    getValuta = () => {
        if(this.state.wish_list_items.length > 0){
            return this.state.wish_list_items[0].valuta
        }
        return ''
    }
    

    render(){
        const { loading, wish_list_items } = this.state;
        const { token } = this.props.match.params;
        if(loading){
            return (
                <div style={{display:'flex', backgroundColor:'black', justifyContent:'center', alignItems:'center', alignContent:'center', height:'100vh'}}>
                    <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                        <FireworkSpinner
                            size={30}
                            color="white"
                            loading={loading}
                        />
                        <h1 style={{color:'white'}}>caricamento...</h1>
                    </div>
                </div>
            );
        }


        return(
            <React.Fragment>
                <ToastContainer />
                
                <div className="container">
                    
                    <Header token={token} wishListItems={wish_list_items.length} sendEmail={this.sendEmail} totalWishlist={this.calculateTotalWish} totalWishlistQuantity={this.calculateTotalQuantityWish} valuta={this.getValuta} />

                    <div className="collections-body wishlist-body">

                        {wish_list_items.length == 0 && (
                            <h1 style={{marginTop:50}} className="has-text-centered is-size-3">No product selected</h1>
                        )}

                        {wish_list_items.length > 0 && (
                            <table style={{marginLeft: '-12px'}} className="wishlist-table">
                                <thead>
                                <tr>
                                    <th style={{width:'200px'}}>
                                        #
                                    </th>
                                    <th style={{width:'141px'}}>CODE</th>
                                    <th>MODEL</th>
                                    <th style={{padding:5, fontSize:14}}>W.PRICE</th>
                                    {/* <th style={{padding:5, fontSize:14}}>R.PRICE</th> */}
                                    <th>35</th>
                                    <th>36</th>
                                    <th>37</th>
                                    <th>38</th>
                                    <th>39</th>
                                    <th>40</th>
                                    <th>41</th>
                                    <th>42</th>
                                    <th>43</th>
                                    <th>44</th>
                                    <th>45</th>
                                    <th>46</th>
                                    <th>47</th>
                                    <th style={{width:'60px'}}>Q.</th>
                                    <th style={{width:'80px'}}>V.</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {wish_list_items.map(item => {
                                        return(
                                            <tr key={item.id}>
                                                <td style={{width:'200px'}}>
                                                    <Link to={`/${token}/collections/${item.collection_id.id}/product/${item.id}`}>
                                                        <img src={`${process.env.REACT_APP_API}/${item.thumb}`} alt="" />
                                                    </Link>
                                                </td>
                                                <td  style={{fontSize:12,width:'141px'}}>
                                                    <div>{item.sku}</div>
                                                    <div><button style={{marginTop:10}} onClick={(e) => this.removeItem(e, item)} className="button is-dark is-small">Remove</button></div>
                                                </td>
                                                <td style={{fontSize:14, fontWeight:700}}>{item.collection_id.nome_collezione}</td>
                                                <td>{item.valuta} {item.whs_price}</td>
                                                {/* <td>{item.ret_price}</td> */}
                                                {[35,36,37,38,39,40,41,42,43,44,45,46,47].map(i => {
                                                    return(
                                                        <td key={i} style={{overflow:'hidden'}}>

                                                            {this.checkSizeAvailability(item.id, i) ? (
                                                                <input type="text" value={this.returnSizeValue(item.id, i)} 
                                                                onChange={e => this.updateInputValue(e, item.id, i)} 
                                                                onBlur={e => this.updateServerValues(e)}
                                                                placeholder="0" style={{border:'none', width:'100%', textAlign:'center'}}/>
                                                            ):("-")}

                                                            
                                                        </td>
                                                    )
                                                })}
                                                <td>{this.returnTotalQty(item.id)}</td>
                                                <td>{item.valuta} {this.returnTotalPrice(item.id)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )}
                       
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default WishList