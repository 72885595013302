import React from 'react';
import api from '../services/api'
import { FireworkSpinner } from "react-spinners-kit";
import {
    Link
  } from "react-router-dom";
import Config from "../config";
import add from "../images/add.png";
import correct from "../images/correct.png";
import wishList from "../images/list.png";
import Header from "../components/Header";

class SingleCollection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            items:[],
            filterItems:[]
        };
    }

    async componentDidMount(){
        const { token, id } = this.props.match.params;
        const resp = await api.get(`items/collection/${id}/with-relations?access_token=${token}`).catch(() => {})
        if(resp){
            setTimeout(() => {
                this.setState({loading:false, items:resp.data, filterItems:resp.data}) 
            }, 500);
        }
    }

    checkIfWishListHasItem = (item) => {
        if(!item.wish_lists){
            return false
        }
        
        if(item.wish_lists.wish_list_items.length === 0){
            return false
        }

        const found = item.wish_lists.wish_list_items.find(el => el.item === item.id)

        if(!found){
            return false
        }

        return true

    }

    singleCollectionGenderSelect = (e) => {
        const gender = e.target.value
        if(gender === 'all'){
            this.setState({filterItems:this.state.items})
        }else{
            const _filterItems = this.state.items.filter(item => {
                if(!item.gender){
                    return false
                }
                return item.gender.toLowerCase() === gender;
            })
            this.setState({filterItems:_filterItems})
        }
        
    }

    render(){
        const { loading, items, filterItems } = this.state;
        const { token, id } = this.props.match.params;
        if(loading){
            return (
                <div style={{display:'flex', backgroundColor:'black', justifyContent:'center', alignItems:'center', alignContent:'center', height:'100vh'}}>
                    <div style={{display:'flex', alignItems:'center', flexDirection:'column'}}>
                        <FireworkSpinner
                            size={30}
                            color="white"
                            loading={loading}
                        />
                        <h1 style={{color:'white'}}>caricamento...</h1>
                    </div>
                </div>
            );
        }

        return(
            <div className="container">
                <Header token={token} collectionName={items[0].collection_id.nome_collezione} singleCollection={true} singleCollectionGenderSelect={this.singleCollectionGenderSelect} />
                <div className="collections-body single">
                    <div className="collection-list columns">
                        {filterItems.map(item => {
                            return (
                                <div key={item.id} className="item column is-3 is-half-mobile">
                                    <Link to={`/${token}/collections/${id}/product/${item.id}`} style={{textDecoration:'none'}}>
                                        <div className="columns item-container" style={{backgroundImage:`url(${process.env.REACT_APP_API}/${item.preview_image})`}}>
                                            <div className="column">
                                                <div className="columns single-collection-item-name-container" style={{display:'flex'}}>
                                                    <div className="column is-10 flex-child is-three-quarters-mobile">
                                                        <h6 style={{textDecoration:'underline'}} className="has-text-weight-bold">{item.collection_id.nome_collezione} {item.lining == 'Shearling' ? item.lining : item.material}</h6>
                                                        <h6 style={{textDecoration:'underline'}}>{item.sku}</h6>
                                                        <h6 style={{textTransform:'uppercase'}}>{item.gender}</h6>
                                                    </div>
                                                    <div className="column has-text-right">
                                                        {this.checkIfWishListHasItem(item) ? (
                                                            <img src={correct} alt="" />
                                                        ):(
                                                            <img src={add} alt="" />
                                                        )}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="column" style={{display: 'flex',alignItems: 'flex-end'}}>
                                                <div className="columns" style={{flexDirection:'column', paddingBottom:10}}>
                                                    <div className="column" style={{paddingBottom:0, paddingTop:0}}><h6>W. SALE PRICE: {item.valuta} {item.whs_price}</h6></div>
                                                    <div className="column" style={{paddingBottom:0, paddingTop:0}}><h6>RETAIL PRICE: {item.valuta} {item.ret_price}</h6></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default SingleCollection