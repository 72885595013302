import React from "react";
import {
    Link
  } from "react-router-dom";
import wishList from "../images/list.png";
import Logo from "../images/logo.png";

export default function Header(props){
    return (
        <div id="menu">
            <div className="columns">
                <div className="logo-container column is-3 has-text-centered">
                    <img src={Logo} alt="" />
                </div>
                <div className="column cart-container">
                    <div className="columns" style={{justifyContent:'flex-end'}}>
                        <div className="column">
                            <div className="columns" style={{justifyContent:'flex-end'}}>
                                <Link to={`/${props.token}/wish-list`} style={{color:'black'}}>
                                    <div className="column" style={{display: 'flex',alignItems: 'center',marginTop: '20px'}}>
                                        <div style={{marginRight: '15px'}}>Selection</div>
                                        <img style={{width:35}} src={wishList} alt="" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="columns" style={{justifyContent:'flex-end'}}>
                        <div className="column">
                            <div className="columns" style={{justifyContent:'flex-end'}}>
                                <a href="https://vsr.hidnander.com/api/uploads/Hidnander_fw22_lookbook_lr.pdf" target="_blank" rel="noopener noreferrer" style={{color:'black'}}>
                                    <div className="column" style={{display: 'flex',alignItems: 'center'}}>
                                        <div>Go to FW/2022 Lookbook</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="seasons-container" className="columns">
                <div className="column is-3 has-text-centered" style={{borderRight:'1px solid'}}>
                    <Link to={`/${props.token}`} style={{color:'black'}}>
                        <h3 className="is-size-7 has-text-weight-bold">FW/22 COLLECTION</h3>
                    </Link>
                </div>
                {props.collectionName && (
                    <div className="column is-3 has-text-centered" style={{borderRight:'1px solid',  background:'rgb(210,211,203)'}}>
                            <h3 className="is-size-7 has-text-weight-bold">{props.collectionName}</h3>
                    </div> 
                )}
                {props.wishListItems && props.wishListItems > 0 ? (
                        <div className="columns" style={{padding:0, width:'100%'}}>
                            <div className="column" style={{display:'flex', alignItems:'center'}}>
                                <div style={{marginLeft:10}}><b>T. VALUE: {props.valuta()} {props.totalWishlist()}</b></div>
                                <div style={{marginLeft:10}}><b>T. QUANTITY: {props.totalWishlistQuantity()}</b></div>
                            </div>
                            <div className="column" style={{display:'flex', justifyContent:'flex-end'}}>
                                <button onClick={props.sendEmail} style={{marginBottom:0, height:35, marginTop:4, marginRight:10, fontSize:15}} className="button is-dark">Send via e-mail</button>
                            </div>
                        </div>
                        ):(
                        <React.Fragment>
                            {props.singleCollection && (
                                    <div className="column" style={{display: 'flex',justifyContent: 'flex-end', padding:0, alignItems:'center'}}>
                                        <div id="select-gender" className="select">
                                            <select onChange={(e) => props.singleCollectionGenderSelect(e)}>
                                                <option value="all">ALL</option>
                                                <option value="man">MAN</option>
                                                <option value="woman">WOMAN</option>
                                            </select>
                                        </div>
                                    </div> 
                            )}
                        </React.Fragment>)}
                
                {/* <div className="column" style={{borderBottom:'1px solid'}}></div> */}
            </div>
        </div>
    )
}