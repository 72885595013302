import React from 'react';
import $ from 'jquery';
import CloseIcon from "../images/close.png";

class ModalCart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSize:{},
        };
    }

    componentDidUpdate(){
        $(document).ready(function(){
            const width = $(window).width();
              if(width <= 767){
                $('body').css('overflow', 'hidden')
                $('body').css('position', 'fixed')
              }
        })
    }

    componentWillUnmount() {
        $('body').css('overflow', 'initial')
        $('body').css('position', 'initial')
    }
    
    setBaseData(data){
        this.setState({selectedSize:JSON.parse(JSON.stringify(data)) })
    }

    cleanAll = () => {
        let selected = JSON.parse(JSON.stringify(this.state.selectedSize))
        
        for (const key in selected) {
            if (selected.hasOwnProperty(key)) {
                selected[key] = 0
            }
        }

        this.setState({selectedSize:selected})
    }

    updateSize = (sign, size) => {
        let selected = JSON.parse(JSON.stringify(this.state.selectedSize))
        let sel = selected[size] ? selected[size] : 0
        sel = parseInt(sel)
        
        if(sign == '-'){
            if(sel-1 < 0){
                sel = 0
            }else{
                sel -=1
            }
        }else{
            sel +=1
        }
        selected[size] = sel
        this.setState({selectedSize:selected})
    }

    stringSizeToArray = size => {
        let sizeArr = []
        let _size = size.split('-')
        let start = parseInt(_size[0])
        let end = parseInt(_size[1])
        let count = end - start
        for (let i = 0; i <= count; i++) {
            sizeArr.push(start+i)
        }

        return sizeArr        
    }

    render(){

        const {item} = this.props

        return (
            <div style={{position:'fixed', top:0, left:0, width:'100%', height:'100vh', background:'rgb(97,107,111)', zIndex:'999', paddingBottom:100}}>
                <a style={{position:'fixed', top:10, right:10, zIndex:'9999'}} onClick={(e) => {this.props.modalCallback();
                }}>
                    <div>
                        <img src={CloseIcon} style={{maxWidth:25}} alt="" />
                    </div>
                </a>
                <div style={{height:'100%', overflowY:'scroll'}} className="container">
                    <div className="columns">
                        <div className="column">
                            <div style={{padding:'30px 10px 0px', color:'white'}}>
                                <h1 className="is-size-5 has-text-weight-bold lh-2rem">{item.collection_id.nome_collezione}</h1>
                                <h1 className="is-size-5 has-text-weight-bold lh-2rem">{item.material}</h1>
                                <h3 className="is-size-6 lh-2rem">{item.sku}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <div className="columns has-text-centered" style={{display:'flex', borderTop:'1px solid white', borderBottom:'1px solid white', color:'white', marginBottom:0}}>
                                <div className="column" style={{borderRight:'1px solid white'}}>SIZE</div>
                                <div className="column">QTY</div>
                            </div>
                            {this.stringSizeToArray(item.size).map((size, index) => {
                                return(
                                    <div key={index} className="columns has-text-centered" style={{display:'flex', borderBottom:'1px solid white', color:'white', marginBottom:0}}>
                                        <div className="column" style={{borderRight:'1px solid white'}}>{size}</div>
                                        <div className="column">
                                            <div className="columns" style={{display:'flex'}}>
                                                <div className="column" style={{padding:0}} onClick={(e) => this.updateSize('-', size)}>-</div>
                                                <div className="column" style={{padding:0}}>{this.state.selectedSize[size] || '0'}</div>
                                                <div className="column" style={{padding:0}} onClick={(e) => this.updateSize('+', size)}>+</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {this.stringSizeToArray(item.size).map((size, index) => {
                                return(
                                    <div key={index} className="columns has-text-centered" style={{display:'flex', borderBottom:'1px solid white', color:'white', marginBottom:0}}>
                                        <div className="column" style={{borderRight:'1px solid white'}}>{size}</div>
                                        <div className="column">
                                            <div className="columns" style={{display:'flex'}}>
                                                <div className="column" style={{padding:0}} onClick={(e) => this.updateSize('-', size)}>-</div>
                                                <div className="column" style={{padding:0}}>{this.state.selectedSize[size] || '0'}</div>
                                                <div className="column" style={{padding:0}} onClick={(e) => this.updateSize('+', size)}>+</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                    <div className="columns" style={{display:'flex', padding:10, marginTop:50}}>
                        <div onClick={this.cleanAll} className="column has-text-centered" style={{background:'white', color:'black'}}>
                            CLEAN ALL
                        </div>
                        <div onClick={(e) => this.props.updateSizeQty(this.state.selectedSize)} className="column has-text-centered" style={{background:'black', color:'white'}}>
                            CONFIRM
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalCart