import React from 'react'

class SelectSeason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render(){
        return(
            <div style={{background: 'rgb(234, 234, 226)', display:'flex', height:'100vh', alignItems:'center'}}>
                <a style={{flex:1, textAlign:'center', fontSize:30, fontWeight:'bold'}}>SS21</a>
                <a style={{flex:1, textAlign:'center', fontSize:30, fontWeight:'bold'}}>SS22</a>
                <div style={{width:1, height:'100vh', position:'absolute', left:'50%', background:'black'}}></div>
            </div>
        )
    }

}

export default SelectSeason