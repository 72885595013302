import axios from 'axios';
//import Toast from 'react-native-root-toast';
// import store from './store';
// import * as RootNavigation from '../RootNavigation';

const baseDomain = process.env.REACT_APP_API
const baseURL = `${baseDomain}`


const instance =  axios.create({
    baseURL
});

instance.interceptors.response.use(function(response){    
    // if(response.headers.version){
    //     if(localStorage.getItem('comparatore_ass_v')){
    //         if(response.headers.version !== localStorage.getItem('comparatore_ass_v')){
    //             Vue.toasted.show('App aggiornata all\'ultima versione: '+response.headers.version).goAway(3000) 

    //             setTimeout(() => {
    //                 localStorage.setItem('comparatore_ass_v', response.headers.version)
    //                 location.reload(true)     
    //             }, 3000);
    //         }
    //     }else{
    //         localStorage.setItem('comparatore_ass_v', response.headers.version)
    //     }
    // }


    if(response.status == 200){
        if(response.data && response.data.status == 'error' || 
            response.data && response.data.status == 'errors' ){

                if(response.data.status == 'error'){
                    //Toast.show(response.data.message, {duration: Toast.durations.SHORT, position: 50, backgroundColor:'red', fontSize:12})
                }

                if(response.data.status == 'errors'){
                    var message = [];
                    for (const key in response.data.message) {
                        if (response.data.message.hasOwnProperty(key)) {
                            const element = response.data.message[key];
                            element.forEach(mess => {
                                message.push(mess)
                            });
                        }
                    }
                    //Toast.show(message.join(','), {duration: Toast.durations.SHORT, position: 50, backgroundColor:'red', fontSize:12})
                }

                return Promise.reject(response);
            }
    }

    return response
    
}, function (err) {    
    switch (err.response.status) {
        case 405:

            break;
        case 404:
            
            break
        case 402:
            
            break
        case 401:
            console.error(err);
            window.location.href = '/unauthorized'
            break;
        default:
            break;
    }

    return Promise.reject(err);
  });

export default instance